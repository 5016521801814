var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards-carrousel px-4 lg:px-0"},[_c('vue-glide',{ref:"carousel",attrs:{"breakpoints":_vm.breakpoints,"options":_vm.options},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('button',{staticClass:"hidden lg:block absolute top-1/2 -mt-6 -ml-20 chevron-btn",class:{
                    'chevron-btn--dark' : _vm.isBtnDark,
                    'chevron-btn--light' : !_vm.isBtnDark,
                    'lg:hidden': _vm.nbCards <= _vm.options.perView
                },attrs:{"data-glide-dir":"<"}}),_c('button',{staticClass:"hidden lg:block absolute top-1/2 -mt-6 right-0 -mr-20 chevron-btn chevron-btn chevron-btn--right",class:{
                    'chevron-btn--dark' : _vm.isBtnDark,
                    'chevron-btn--light' : !_vm.isBtnDark,
                    'lg:hidden': _vm.nbCards <= _vm.options.perView
                },attrs:{"data-glide-dir":">"}})]},proxy:true}]),model:{value:(_vm.currentSlideId),callback:function ($$v) {_vm.currentSlideId=$$v},expression:"currentSlideId"}},[_vm._t("default")],2),_c('ul',{staticClass:"list-none flex justify-center mt-6 lg:mt-10",class:{'lg:hidden' : _vm.nbCards <= _vm.options.perView}},_vm._l((_vm.nbCards),function(i){return _c('li',{key:i,staticClass:"mx-1",class:{'lg:hidden': i > _vm.nbCards-2}},[_c('button',{staticClass:"w-2 h-2 rounded-full",class:{
                    'bg-black': _vm.isBtnDark,
                    'bg-white': !_vm.isBtnDark,
                    'opacity-50': i-1 !== _vm.currentSlideId,
                },on:{"click":function($event){_vm.currentSlideId = i-1}}})])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }