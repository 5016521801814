<template>
    <div class="coeurs-animation absolute z-20"></div>
</template>

<script>
    import lottie from "lottie-web";
    import coeurs from "../../static/animations/coeurs.json";

    export default {
        name: "CoeursAnimation",

        data() {
            return {
                animation: null,
            }
        },

        mounted() {
            this.animation = lottie.loadAnimation({
                name: 'coeurs',
                container: this.$el,
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: coeurs,
            });

            this.animation.setSpeed(1.4);

            this.animation.play();
        }
    }
</script>

<style scoped lang="scss">
    .coeurs-animation {
        top: -10px;
        left: calc(50% + 10px);
    }
</style>
