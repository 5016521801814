<template>
    <div :id="identifier"></div>
</template>

<script>
    export default {
        name: "Anchor",

        data() {
            return {
                identifier: null,
            }
        },

        props: {
            anchorId: {
                type: String,
                required: true,
            }
        },

        mounted() {
            setTimeout(() => {
                this.identifier = this.anchorId;

                if (window.location.hash === '#'+this.anchorId) {
                    this.$el.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        },
    }
</script>
