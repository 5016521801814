import "./assets/styles/main.scss";

import pictureFill from "picturefill";
import objectFitPolyfill from "object-fit-images";

import Vue from "vue";
import CsrfInput from "@/app/components/CsrfInput";
import HeaderNavbar from "@/app/components/HeaderNavbar";
import HeroHome from "@/app/components/HeroHome";
import ContactForm from "@/app/components/ContactForm";
import Accordion from "@/app/components/Accordion";
import CircularCarrousel from "@/app/components/CircularCarrousel";
import ColibriAnimation from "@/app/components/ColibriAnimation";
import CoeursAnimation from "@/app/components/CoeursAnimation";
import Anchor from "@/app/components/Anchor";
import CardsCarrousel from "@/app/components/CardsCarrousel";
import { GlideSlide } from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'


new Vue({
    el: "#app",
    components: {
        CsrfInput,
        HeaderNavbar,
        HeroHome,
        ContactForm,
        Accordion,
        CircularCarrousel,
        ColibriAnimation,
        CoeursAnimation,
        Anchor,
        CardsCarrousel,
        VueGlideSlide: GlideSlide,
    },
    mounted() {
        pictureFill();
        objectFitPolyfill();
    },
});
