<template>
    <div class="cards-carrousel px-4 lg:px-0">
        <vue-glide
            ref="carousel"
            :breakpoints="breakpoints"
            :options="options"
            v-model="currentSlideId"
        >
            <slot></slot>
            <template #control>
                <button
                    data-glide-dir="<"
                    :class="{
                        'chevron-btn--dark' : isBtnDark,
                        'chevron-btn--light' : !isBtnDark,
                        'lg:hidden': nbCards <= options.perView
                    }"
                    class="hidden lg:block absolute top-1/2 -mt-6 -ml-20 chevron-btn"
                ></button>
                <button
                    data-glide-dir=">"
                    :class="{
                        'chevron-btn--dark' : isBtnDark,
                        'chevron-btn--light' : !isBtnDark,
                        'lg:hidden': nbCards <= options.perView
                    }"
                    class="hidden lg:block absolute top-1/2 -mt-6 right-0 -mr-20 chevron-btn chevron-btn chevron-btn--right"
                ></button>
            </template>
        </vue-glide>

        <ul class="list-none flex justify-center mt-6 lg:mt-10" :class="{'lg:hidden' : nbCards <= options.perView}">
            <li
                v-for="i in nbCards"
                :key="i"
                :class="{'lg:hidden': i > nbCards-2}"
                class="mx-1"
            >
                <button
                    class="w-2 h-2 rounded-full"
                    :class="{
                        'bg-black': isBtnDark,
                        'bg-white': !isBtnDark,
                        'opacity-50': i-1 !== currentSlideId,
                    }"
                    @click="currentSlideId = i-1"
                ></button>
            </li>
        </ul>
    </div>
</template>

<script>
import 'vue-glide-js/dist/vue-glide.css'
import {Glide, GlideSlide} from 'vue-glide-js'

export default {
    name: "CardsCarrousel",

    props: {
        isBtnDark: {
            type: Boolean,
            default: false,
        },
        nbCards: {
            type: Number,
            required: true,
        },
    },

    components: {
        [Glide.name]: Glide,
    },

    data() {
        return {
            currentSlideId: 0,
            breakpoints: {
                992: {
                    perView: 1,
                    gap: 16,
                }
            },

            options: {
                perView: 3,
                gap: 30,
                bound: true,
                rewind: false,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.cards-carrousel {
    ::v-deep .glide__track {
        @apply overflow-visible;

        @screen lg {
            @apply overflow-hidden;
        }
    }

    ::v-deep .glide__slides {
        @apply items-stretch;
    }

    ::v-deep .glide__slides li {
        height: auto;

        &::before {
            display: none;
        }
    }
}
</style>
