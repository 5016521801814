<template>
    <section class="circular-carrousel overflow-hidden">
        <div class="container grid-container h-full">
            <div class="grid-row h-full">
                <!-- Images desktop -->
                <div class="grid-col hidden lg:flex w-1/2 relative">
                    <template v-for="(slide, key) in slides">
                        <transition name="opacity-fade" :key="key">
                            <img
                                :src="slide.image.url"
                                :alt="slide.image.alt"
                                :class="getImageClass(key)"
                                class="circular-carrousel__slide-image"
                            >
                        </transition>
                    </template>
                </div>

                <!-- Text content -->
                <div class="grid-col w-full lg:w-5/12 h-full relative flex flex-col justify-center">
                    <button
                        class="circular-carrousel__arrow-button circular-carrousel__arrow-button--left arrow-button arrow-button--primary arrow-button--left"
                        v-if="currentSlideId > 0"
                        @click="updateCurrentSlide(currentSlideId - 1)"
                    >Previous</button>
                    <button
                        class="circular-carrousel__arrow-button circular-carrousel__arrow-button--right arrow-button arrow-button--primary"
                        v-if="currentSlideId < slides.length - 1"
                        @click="updateCurrentSlide(currentSlideId + 1)"
                    >Next</button>

                    <transition :name="slideTransitionName" mode="out-in">
                        <div
                            :key="currentSlideId"
                            class="flex flex-col items-center lg:items-start text-center lg:text-left pt-8 pb-10 lg:py-0"
                        >
                            <h2 class="w-full">{{ currentSlide.category }}</h2>

                            <!-- Image mobile -->
                            <img
                                :src="currentSlide.image.url"
                                :alt="currentSlide.image.alt"
                                class="lg:hidden mt-8"
                                width="159"
                                height="195"
                            >

                            <p class="w-full mt-6 lg:mt-8 font-fieldwork text-xl font-black">
                                <span class="text-secondary">{{ currentSlideId + 1 }}</span><span class="text-gray-500"> - {{ slides.length }}</span>
                            </p>
                            <h3 class="w-full">{{ currentSlide.heading }}</h3>
                            <p v-html="currentSlide.text" class="w-full mt-2"></p>
                            <a :href="currentSlide.cta.url" :target="currentSlide.cta.target" class="btn btn--primary mt-4">
                                {{ currentSlide.cta.label }}
                            </a>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "CircularCarrousel",

        props: {
            slides: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                currentSlideId: 0,
                slideTransitionName: 'next-slide-fade',
            }
        },

        computed: {
            currentSlide() {
                return this.slides[this.currentSlideId];
            }
        },

        methods: {
            updateCurrentSlide(key) {
                key < this.currentSlideId ? this.slideTransitionName = 'previous-slide-fade' :  this.slideTransitionName = 'next-slide-fade';

                if (key === this.slides.length) {
                    this.currentSlideId = 0;
                    return;
                }

                if (key === -1) {
                    this.currentSlideId = this.slides.length - 1;
                    return;
                }

                this.currentSlideId = key;
            },

            getImageClass(key) {
                if (key === this.currentSlideId) {
                    return 'circular-carrousel__slide-image--current';
                }
                if (key === this.currentSlideId-2) {
                    return 'circular-carrousel__slide-image--previous-1';
                }
                if (key === this.currentSlideId-1) {
                    return 'circular-carrousel__slide-image--previous';
                }
                if (key === this.currentSlideId+1) {
                    return 'circular-carrousel__slide-image--next';
                }
                if (key === this.currentSlideId+2) {
                    return 'circular-carrousel__slide-image--next-1';
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .circular-carrousel {
        @screen lg {
            height: 46.25rem;
        }

        &__arrow-button {
            @apply absolute;

            top: 10.625rem;

            @screen lg {
                @apply top-1/2;
            }

            &--left {
                left: 0.5rem;

                @screen lg {
                    left: -5rem;
                }
            }

            &--right {
                right: 0.5rem;

                @screen lg {
                    right: -5rem;
                }
            }
        }

        &__slide-image {
            @apply absolute;
            top: 183px;
            transition: transform 400ms, opacity 400ms;

            transform-origin: left;
            opacity: 0;

            &--current {
                @apply z-10;
                transform: rotate(-3deg);
                opacity: 1;
            }

            &--previous-1 {
                transform: rotate(-20deg) translate(30px, -1000px);
                opacity: 0;
            }

            &--previous {
                transform: rotate(-10deg) translate(20px, -500px);
                opacity: 1;
            }

            &--next {
                transform: rotate(8deg) translate(50px, 500px);
                opacity: 1;
            }

            &--next-1 {
                transform: rotate(16deg) translate(20px, 1000px);
                opacity: 0;
            }
        }
    }

    .previous-slide-fade-enter-active,
    .previous-slide-fade-leave-active,
    .next-slide-fade-enter-active,
    .next-slide-fade-leave-active {
        transition: transform 250ms, opacity 400ms;
    }
    .previous-slide-fade-enter {
        transform: translateX(-50px);
        opacity: 0;
    }
    .previous-slide-fade-leave-to {
        transform: translateX(50px);
        opacity: 0;
    }
    .next-slide-fade-enter {
        transform: translateX(50px);
        opacity: 0;
    }
    .next-slide-fade-leave-to {
        transform: translateX(-50px);
        opacity: 0;
    }

    .opacity-fade-enter-active,
    .opacity-fade-leave-active {
        transition: opacity 200ms;
    }
    .opacity-fade-enter,
    .opacity-fade-leave-to {
        opacity: 0;
    }
</style>
