<template>
    <div class="colibri-animation absolute z-20"></div>
</template>

<script>
    import lottie from "lottie-web";
    import colibri from "../../static/animations/colibri.json";

    export default {
        name: "ColibriAnimation",

        data() {
            return {
                isAnimationPlayed: false,
                animation: null,
            }
        },

        mounted() {
            this.animation = lottie.loadAnimation({
                name: 'colibri',
                container: this.$el,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: colibri,
            });

            this.scrollHandler();
            window.addEventListener('scroll', this.scrollHandler);
        },

        destroyed() {
            window.removeEventListener('scroll', this.scrollHandler);
        },

        methods: {
            checkVisible() {
                var rect = this.$el.getBoundingClientRect();
                var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                return !(rect.bottom < 0 || rect.top + 300 - viewHeight >= 0);
            },

            scrollHandler() {
                if (this.isAnimationPlayed) {
                    return;
                }

                if(this.checkVisible()) {
                    window.removeEventListener('scroll', this.scrollHandler);
                    this.isAnimationPlayed = true;
                    this.animation.setSegment(0, 60);
                    this.animation.play();
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .colibri-animation {
        top: -40px;
        left: -36px;

        width: 16.25rem;
    }
</style>
