<template>
    <section class="hero-home relative">
        <picture v-if="backgroundImage.mobile">
            <source v-if="backgroundImage.desktop" :srcset="backgroundImage.desktop.url">
            <img :src="backgroundImage.mobile.url" :alt="backgroundImage.mobile.alt" class="absolute inset-0 w-full h-full object-cover object-center">
        </picture>

        <div class="hero-home__text-content relative container grid-container">
            <div class="grid-row">
                <div class="grid-col w-full">
                    <slot></slot>
                </div>
                <h1 class="grid-col w-full lg:w-3/4 flex flex-col">
                    <span class="hero-home__heading relative z-10 h2 text-white lg:mb-2" v-html="heading"></span>
                    <transition name="slide-fade" mode="out-in">
                        <template v-for="(commitment, key) in commitments">
                            <span v-if="key === currentCommitmentId" :key="key" class="big-title text-white">
                                {{ commitment }}
                            </span>
                        </template>
                    </transition>
                </h1>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HeroHome",

        props: {
            heading: {
                type: String,
                required: true,
            },
            commitments: {
                type: Array,
                required: true,
            },
            backgroundImage: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                currentCommitmentId: 0,
            }
        },

        mounted() {
            setInterval(this.updateCurrentCommitment, 5000);
        },

        methods: {
            updateCurrentCommitment() {
                if (this.currentCommitmentId === this.commitments.length-1) {
                    this.currentCommitmentId = 0;
                    return;
                }

                this.currentCommitmentId++;
            }
        }
    }
</script>

<style scoped lang="scss">
    .hero-home {
        height: 30rem;

        @screen lg {
            height: 39.0625rem;
        }

        &__text-content {
            padding-top: 4.5rem;

            @screen lg {
                padding-top: 10.5rem;
            }
        }

        &__heading {
            ::v-deep strong, ::v-deep a {
                @apply text-white underline;
            }

            ::v-deep a {
                transition: color 200ms;

                &:hover {
                    @apply text-secondary;
                }
            }
        }
    }

    .slide-fade-enter-active {
        transition: all 1s;
    }
    .slide-fade-leave-active {
        transition: all 1s;
    }
    .slide-fade-enter {
        transform: translateX(50px);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(-50px);
        opacity: 0;
    }
</style>
