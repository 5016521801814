<template>
    <form
        class="contact-form"
        @submit.prevent="onFormSubmit"
        ref="form"
    >
        <csrf-input/>
        <slot
            :validation-errors="validationErrors"
            :state="state"
        ></slot>
    </form>
</template>

<script>
import axios from 'axios';
import CsrfInput from "./CsrfInput";

export default {
    name: "ContactForm",

    props: {
        successRedirectUrl: {type: String, default: null},
    },

    components: {
        CsrfInput,
    },

    data() {
        return {
            validationErrors: {},
            state: 'idle', // allowed values: idle, validation-failed, submitted or unexpected-error
        }
    },

    methods: {
        async onFormSubmit(event) {
            const payload = new FormData(event.currentTarget);

            // Clear error messages
            this.validationErrors = {};

            try {
                this.state = 'submitting';
                const response = await axios.post(document.location.href, payload);

                if (this.successRedirectUrl) {
                    document.location = this.successRedirectUrl;
                    return;
                }

                this.state = 'submitted';
            } catch (errorResponse) {
                if (errorResponse.response.status === 400) {
                    this.validationErrors = errorResponse.response.data.errors;
                    this.state = 'validation-failed';
                    this.$nextTick(this.scrollToFirstInputWithError);
                    return;
                }

                this.state = 'unexpected-error';
            }

            this.scrollToFirstInputWithError();
        },

        scrollToFirstInputWithError() {
            let element = document.querySelector('.input-error');

            if (!element) {
                return;
            }

            element.scrollIntoView();
        },
    }
}
</script>
