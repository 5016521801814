<template>
    <div class="relative">
        <transition name="fade">
            <div v-if="isSearchMenuOpened" class="header-navbar__dimmer z-40 bg-black fixed top-0 w-screen h-screen" @click="isSearchMenuOpened = false"></div>
        </transition>

        <header class="header-navbar relative z-50 flex items-center justify-between bg-white border-b border-gray-650 px-4 xl:px-10">
            <button class="xl:hidden bg-transparent border-0 focus:outline-none" @click="isMobileMenuOpened = true">
                <img src="@/static/images/icons/menu.svg" alt="Menu">
            </button>

            <div class="flex items-center h-full">
                <a :href="homeUrl" class="xl:mr-10 flex-shrink-0">
                    <img :src="logo.url" :alt="logo.alt" class="header-navbar__logo">
                </a>

                <div class="hidden xl:flex items-stretch h-full">
                    <a
                        v-for="(item, key) in mainMenu"
                        :href="item.url"
                        :key="key"
                        :target="item.target"
                        :class="{'navigation--active border-gray-300': encodeURI(item.url) === currentUrl}"
                        class="header-navbar__menu-item navigation flex items-center h-full mx-3 text-gray-300 border-b-3 border-transparent"
                    >
                        {{ item.label }}
                    </a>
                </div>
            </div>

            <div class="flex items-center">
                <button class="flex-shrink-0 bg-transparent border-0 focus:outline-none xl:mr-6" @click="isSearchMenuOpened = !isSearchMenuOpened">
                    <img src="@/static/images/icons/search.svg" alt="Search">
                </button>

                <div class="relative" @click.stop>
                    <button
                        :class="isBrandsMenuOpened ? 'rounded-t' : 'rounded'"
                        class="header-navbar__brands-menu hidden xl:flex flex-col items-center border border-gray-650 p-2 font-futura font-medium text-primary uppercase focus:outline-none"
                        @click="isBrandsMenuOpened = !isBrandsMenuOpened"
                    >
                        <span class="w-full">{{ brandsMenu.heading }}</span>
                        <img src="@/static/images/icons/chevron-down.svg" alt="Chevron">
                    </button>

                    <transition name="menu-fade">
                        <span v-if="isBrandsMenuOpened" class="header-navbar__brands-menu-items absolute z-60 left-0 right-0 flex flex-col bg-white border-l border-b border-r border-gray-650 rounded-b">
                            <a
                                v-for="(item, key) in brandsMenu.brands"
                                :key="key"
                                :href="item.cta.url"
                                :target="item.cta.target"
                                :class="{'border-t border-gray-650': key > 0}"
                                class="p-2 flex justify-center items-center"
                            >
                                <img :srcset="item.logo.url + ' 2x'" :alt="item.logo.alt">
                            </a>
                        </span>
                    </transition>
                </div>
            </div>

            <transition name="fade">
                <div v-if="isSearchMenuOpened" class="header-navbar__search-menu absolute z-50 left-0 right-0 w-full h-20 bg-gray-700">
                    <div class="container grid-container h-full">
                        <form class="flex justify-center items-center h-full" :action="searchFormAction">
                            <input
                                type="search"
                                :placeholder="searchInputPlaceholder"
                                class="header-navbar__search-input body w-full mr-4 p-2 text-gray-300 border-b border-gray-600 bg-transparent focus:outline-none"
                                name="q"
                            >
                            <button type="submit" class="btn btn--primary">{{ searchSubmitText }}</button>
                        </form>
                    </div>
                </div>
            </transition>
        </header>

        <transition name="menu-fade">
            <div v-if="isMobileMenuOpened" class="fixed z-50 inset-0 xl:hidden w-screen h-screen bg-white overflow-auto">
                <header class="h-20 border-b border-gray-650">
                    <div class="grid-container container flex justify-between items-center h-full">
                        <img :src="logo.url" :alt="logo.alt" class="header-navbar__logo">

                        <button class="bg-transparent border-0 focus:outline-none" @click="isMobileMenuOpened = false">
                            <img src="@/static/images/icons/cross.svg" alt="Close">
                        </button>
                    </div>

                    <div class="flex flex-col pt-6 pb-16 border-b border-gray-650">
                        <a
                            v-for="(item, key) in mainMenu"
                            :href="item.url"
                            :key="key"
                            :target="item.target"
                            :class="{'navigation--active border-l-3': encodeURI(item.url) === currentUrl}"
                            class="navigation flex items-center h-10 my-2 px-8"
                        >
                            {{ item.label }}
                        </a>
                    </div>

                    <div class="flex px-8 py-6">
                        <a
                            v-for="(item, key) in brandsMenu.brands"
                            :key="key"
                            :href="item.cta.url"
                            :target="item.cta.target"
                            class="flex justify-center items-center mr-4 h-16"
                        >
                            <img :srcset="item.logo.url + ' 2x'" :alt="item.logo.alt" class="max-h-full">
                        </a>
                    </div>
                </header>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "HeaderNavbar",

    props: {
        logo: {
            type: Object,
            required: true,
        },
        mainMenu: {
            type: Array,
            required: true,
        },
        brandsMenu: {
            type: Object,
            required: true,
        },
        homeUrl: {
            type: String,
            required: true,
        },
        currentUrl: {
            type: String,
            required: true,
        },
        searchSubmitText: {
            type: String,
            required: true,
        },
        searchFormAction: {
            type: String,
            required: true,
        },
        searchInputPlaceholder: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isBrandsMenuOpened: false,
            isSearchMenuOpened: false,
            isMobileMenuOpened: false,
        }
    },

    mounted() {
        window.addEventListener('click', this.windowClickHandler);
    },

    destroyed() {
        window.removeEventListener('click', this.windowClickHandler);
    },

    methods: {
        windowClickHandler() {
            this.isBrandsMenuOpened = false;
        },
    }
}
</script>

<style scoped lang="scss">
.header-navbar {
    height: 4rem;

    @screen xl {
        height: 6.25rem;
    }

    &__logo {
        width: 95px;
        height: auto;

        @screen xl {
            width: 128px;
        }
    }

    &__menu-item {
        transition: border-color 150ms;

        &:hover {
            @apply border-gray-300 no-underline;
        }
    }

    &__brands-menu {
        width: 6.875rem;
        font-size: 0.8125rem;
    }

    &__brands-menu-items {
        width: 100%;
        top: 100%;
    }

    &__search-menu {
        top: 100%;
    }

    &__search-input {
        @screen xl {
            width: 25rem;
        }
    }

    &__dimmer {
        opacity: 0.35;
    }
}

.menu-fade-enter-active, .menu-fade-leave-active {
    transition: opacity 150ms, transform 150ms;
}

.menu-fade-enter, .menu-fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 150ms;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
